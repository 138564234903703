import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {  getAuth ,signInWithPhoneNumber,RecaptchaVerifier,getfire} from 'firebase/auth';
import { app } from 'configs/firebaseConfig'
import { collection, addDoc,getFirestore } from 'firebase/firestore';
import {auth} from 'configs/firebaseConfig'
import { useEffect } from 'react'


function useAuth() {
    const dispatch = useDispatch()
   

    const navigate = useNavigate()

    useEffect(() => {
        // Ensure the reCAPTCHA container is rendered
        const recaptchaContainer = document.getElementById('recaptcha-container');
        if (!recaptchaContainer) {
          const container = document.createElement('div');
          container.id = 'recaptcha-container';
          document.body.appendChild(container);
        }
    
        // Initialize RecaptchaVerifier
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'normal',
                'callback': (response) => {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  // ...
                },
                'expired-callback': () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                  // ...
                }
              });
        }
      }, []);

  

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {

        dispatch(onSignInSuccess("token"))
        // try {
        //     const resp = await apiSignIn(values)
        //     if (resp.data) {
        //         const { token } = resp.data
        //         dispatch(onSignInSuccess(token))
        //         if (resp.data.user) {
        //             dispatch(
        //                 setUser(
        //                     resp.data.user || {
        //                         avatar: '',
        //                         userName: 'Anonymous',
        //                         authority: ['USER'],
        //                         email: '',
        //                     }
        //                 )
        //             )
        //         }
        //         const redirectUrl = query.get(REDIRECT_URL_KEY)
        //         navigate(
        //             redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
        //         )
        //         return {
        //             status: 'success',
        //             message: '',
        //         }
        //     }
        // } catch (errors) {
        //     return {
        //         status: 'failed',
        //         message: errors?.response?.data?.message || errors.toString(),
        //     }
        // }
    }

  
  



    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
       // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
     //   sendSignInCode,
     //   verifyOtp
    }
}

export default useAuth
