const appConfig = {
    apiPrefix: 'https://us-central1-aitcfirebase.cloudfunctions.net',
  // apiPrefix:"https://us-central1-solar-icon-418811.cloudfunctions.net",
  //  apiPrefix:"http://127.0.0.1:5001/aitcfirebase/us-central1",
    apiFullServerPath:'http://127.0.0.1:5001/aitcfirebase/us-central1/sendVerificationCode',
    olaMapsBaseURL: "https://api.olamaps.io/places/v1",
    olaMapKey: "dvvvxazHOMWB4qdbAuJhweiSSKXwymko0BFR73kQ",
    authenticatedEntryPath: '/book-trip',
    unAuthenticatedEntryPath: '/booking-form',
    tourPath: '/bookig-form',
    locale: 'en',
    enableMock: true,
    site_key_captcha:'6LfAelQqAAAAAPCG8QaU7S6B2SXTzsIhdX0OWkgh'
}

export default appConfig
