import { createSlice } from '@reduxjs/toolkit'


export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        dialogueOpen: true,
        userLoginData: {},
        userId:'',
        refreshToken:'',
        phoneNumber:''
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.accessToken
            state.userLoginData = action.payload
            state.userId = action.payload.uid
            state.phoneNumber = action.payload.phoneNumber
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.userLoginData = {}
            state.userId = ''
            state.phoneNumber = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        signInDialogueOpen: (state, action) => {
            state.dialogueOpen = action.payload
        },
        updateAuthTokens: (state, action) => {
            state.token = action.payload.accessToken
            state.refreshToken= action.payload.refreshToken
        },
        setuseLoginData: (state, action) => {
            state.userLoginData = action.payload
          //  state.token = action.payload.accessToken
            state.userId = action.payload.uid

        }
    },
})

export const { onSignInSuccess, onSignOutSuccess,updateAuthTokens, setToken,signInDialogueOpen,setuseLoginData } =
    sessionSlice.actions

export default sessionSlice.reducer
